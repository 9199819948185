@import "./vars.scss";

body {
  font-family: "HelveticaNeueCyr" !important;
  font-weight: 100 !important;
}

/* fonts */
@font-face {
  font-family: "QuamBlackItalic";
  src: url("../fonts/Quam-BlackItalic.otf") format("opentype");
}

@font-face {
  font-family: "EncodeSansExpanded";
  src: url("../fonts/encode-sans-expanded-extralight-regular.ttf") format("opentype");
}

@font-face {
  font-family: "HelveticaNeueCyr";
  src: url("../fonts/helveticaneuecyr-roman.otf") format("opentype");
}

/* makes pushable panel height to screen height */
.pushable {
  min-height: 85vh;
}

/* site header */
.top_panel_header {
  font-family: "EncodeSansExpanded";
  font-weight: 100;
  color: $top-panel-text !important;
  text-transform: uppercase !important;
  span {
    letter-spacing: 0.7vw;
    font-size: 1vw;
  }
}
.ui.inverted.menu,
.ui.inverted.menu:not(.vertical):hover {
  .item,
  .active.item,
  .item > a:not(.ui) {
    color: $top-panel-text !important;
  }
}

.top_panel_header_inner {
  display: block;
  position: relative;
  height: 9vh;
  border-bottom: 0.3vh solid $top-panel-text;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
  .header {
    padding: 0;
    margin: 0;
    font-family: "EncodeSansExpanded";
    font-weight: 100;
    font-size: 2vw !important;
    text-transform: uppercase;
    letter-spacing: 1vw;
    color: $top-panel-text !important;
    vertical-align: middle;
  }
  .top_panel_ico_wrapper {
    height: inherit;
    width: inherit;
    padding: 0 !important;
    margin: 0 !important;
    img {
      display: block;
      position: relative;
      width: 9vh;
      height: 9vh;
      height: inherit;
    }
  }
}

.ui.dropdown {
  .menu {
    border: none;
  }
}

.top_panel_text_wrapper {
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
}

.accordion.ui {
  > .title:not(.ui) {
    padding: 0;
  }
}

.ui.accordion:not(.styled) {
  .title ~ .content:not(.ui) {
    padding: 0;
  }
}

.ui.labeled.icon.menu {
  .left_panel_header {
    margin-top: 0;
    background: $left-panel-background-hovered;
    display: block;
    height: 15vh;
    width: 100%;
    .ui:not(.site_header) {
      top: 50%;
      position: absolute;
      width: 100%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}

.ui.visible.left {
  &.overlay,
  &.push {
    &.sidebar {
      background: $left-panel-background;
    }
  }
}

/* main content wrapper and inner */
.main_content_wrapper {
  background-color: $left-panel-background;
  min-height: 85vh;
  &.ui.grid {
    padding: 2vw;
    display: block !important;
  }
}

.main_content {
  background-color: $form-background;
  border-radius: 0.28rem;
}

/* form style */
.ui.header {
  font-family: "HelveticaNeueCyr";
  font-weight: 100;
}

.ui.checkbox label {
  font-size: $small-fontsize !important;
}

.ui.checkbox {
  line-height: 1.35;
  .box:before,
  label:before {
    width: 2.2vh;
    height: 2.2vh;
    border: none;
    border-radius: 0.3vh;
  }
  input[type="checkbox"] {
    width: 2.2vh;
    height: 2.2vh;
  }
  .box:after,
  label:after {
    font-size: $small-fontsize;
    width: 2.2vh;
    height: 2.2vh;
  }
}

/*input dropdown*/
.ui.selection.dropdown {
  min-height: 3.8vh;
  height: 3.8vh;
  padding: 0.7vh;
  line-height: 1.35;
  border-radius: 0.3vh;
  font-size: $small-fontsize;
  > .dropdown.icon {
    margin: 0;
    padding: 0;
  }
}
.ui.search.ui.selection.dropdown {
  > input.search {
    padding: 0;
  }
}
.ui.search {
  font-size: $small-fontsize;
}

/*input text*/
.ui.form {
  .field > label {
    font-size: $small-fontsize;
    font-weight: 100;
  }
  input[type="text"],
  input[type="tel"],
  input[type="number"],
  input[type="password"] {
    padding: 0.7vh;
    font-size: $small-fontsize !important;
    min-height: 3.8vh;
    height: 3.8vh;
    border-radius: 0.28rem;
  }
}

/* modal style */
.ui.mini.modal {
  > .header:not(.ui) {
    background-color: $left-panel-background;
  }
  > .content {
    background-color: $form-background;
  }
  > .actions {
    background-color: $form-background;
  }
}

/* buttons */
.ui.positive.button,
.ui.positive.button:hover,
.ui.positive.button:focus {
  background-color: $button-positive;
  border-radius: 0.28rem;
}

.ui.negative.button,
.ui.negative.button:hover,
.ui.negative.button:focus {
  background-color: rgba(0, 0, 0, 0.62);
}
.ui.basic.red.label {
  color: rgba(0, 0, 0, 0.62) !important;
  border-color: rgba(0, 0, 0, 0.62) !important;
}

/* hover on left item */
.ui.labeled.icon.menu {
  .item:not(.left_panel_header) {
    border-left: 4px solid $left-panel-background;
  }
  .item:not(.left_panel_header):hover {
    background: $left-panel-background-hovered;
    border-left: 4px solid $hover-text;
    .left_menu_item_text {
      color: $hover-text;
    }
    .left_menu_item_icon {
      fill: $hover-text;
    }
  }
}

.left_menu_item_icon_wrapper {
  width: 25px;
  height: 25px;
  margin-top: -5px;
}
.left_menu_item_icon {
  fill: #dce0e0;
}

/* left panel lines remove */
.ui.vertical.menu {
  .item:before {
    content: none;
  }
}

.ui.menu {
  .item {
    > i.icon {
      margin: 0;
    }
  }
}

.ui.left {
  &.push,
  &.overlay {
    &.sidebar {
      min-height: 85vh;
      max-width: inherit;
      // width:20vw;
    }
  }
}

i.icons .corner.icon {
  font-size: $usual-fontsize;
}

/* top menu */
.ui.attached.inverted.menu {
  &.top_menu_wrapper {
    background: $left-panel-background;
    height: 15vh;
  }
}

.ui.inverted.menu {
  .item {
    &.top_panel_item {
      // &:hover{
      //   background:$left-panel-background-hovered;
      // }
      .top_panel_item_dropdown {
        .menu {
          background: $left-panel-background;
          .item {
            color: $top-panel-text !important;
          }
        }
      }
    }
    &.top_panel_login {
      padding: 0;
      ::selection {
        background: $left-panel-background-hovered;
      }
      .ui.item.dropdown {
        height: 100%;
      }
    }
  }
}

.ui.menu {
  font-family: "HelveticaNeueCyr";
  .item {
    > .input {
      color: $panels-text;
      input[type="text"] {
        background: $left-panel-background-hovered;
        border: none;
        font-family: "HelveticaNeueCyr";
        color: $panels-text;
        &::placeholder {
          color: $panels-text;
        }
      }
    }
  }
}

.ui.menu {
  .item:before {
    content: none;
  }
}

/* main content */
.main_content {
  // min-height: 85vh;
  // max-height: 85vh;
  // overflow-y: scroll;
}

.ui.segment {
  border: none;
  border-radius: 0;
  margin: 0;
}

.fixed {
  position: fixed;
}

.padding-standart {
  padding-top: $padding-standart;
  padding-bottom: $padding-standart;
}

.nopadding {
  padding: 0;
}

.ui.vertical.menu {
  // border: 1px solid rgba(34, 36, 38, 0.15);
  min-height: $vertical-menu-height;
  max-height: $vertical-menu-height;
}

.ui.grid {
  margin-left: 0;
  margin-right: 0;
  > .row.left_menu_item_wrapper {
    padding: 0;
    margin-top: 5px;
  }
  > .row.site_logo_wrapper {
    padding-bottom: 5px;
  }
  margin-top: 0;
  margin-bottom: 0;
  > .row {
    padding: $padding-standart;
    > .column {
      padding: 0;
    }
  }
}

.ui.secondary.pointing {
  &.menu {
    border-bottom: none;
  }
}

// .ui {
//   .pointing {
//     overflow-y: scroll;
//   }
// }

.span_small {
  font-size: $small-fontsize * 0.7 !important;
}

.span_usual {
  font-size: $small-fontsize !important;
}

.span_large {
  font-size: $small-fontsize * 1.6 !important;
}

.span_superlarge {
  font-size: $large-fontsize !important;
}

.transition {
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  transition: all 1s ease-out;
}

/* loading */
.ui.loading.form:before {
  background: $form-background;
}
.ui.inverted.dimmer {
  background: $form-background;
}

/* form message */
.ui.negative.message {
  background: rgba(0, 0, 0, 0.62);
  color: #fff;
  border: none;
  box-shadow: none;
  margin-top: 1rem;
  .header {
    color: #fff;
  }
}

/* mobile screen */
@media screen and (min-width: 640px) and (max-width: 1023px) {
  .span_small {
    font-size: $mobile-small-fontsize * 0.7 !important;
  }

  .span_usual {
    font-size: $mobile-small-fontsize !important;
  }

  .span_large {
    font-size: $mobile-small-fontsize * 1.6 !important;
  }

  .span_superlarge {
    font-size: $mobile-large-fontsize !important;
  }

  .ui.checkbox label {
    font-size: $mobile-small-fontsize !important;
  }

  .ui.checkbox {
    line-height: 1.35;
    .box:before,
    label:before {
      width: 2.2vw;
      height: 2.2vw;
      border: none;
      border-radius: 0.3vw;
    }
    input[type="checkbox"] {
      width: 2.2vw;
      height: 2.2vw;
    }
    .box:after,
    label:after {
      font-size: $mobile-small-fontsize;
      width: 2.2vw;
      height: 2.2vw;
    }
  }

  /*input dropdown*/
  .ui.selection.dropdown {
    min-height: 3.8 * 1.3vw;
    height: 3.8 * 1.3vw;
    padding: 0.7vw;
    line-height: 1.35;
    border-radius: 0.3vw;
    font-size: $mobile-small-fontsize;
    > .dropdown.icon {
      margin: 0;
      padding: 0;
    }
    .menu > .item {
      font-size: $mobile-small-fontsize;
    }
  }
  .ui.search.ui.selection.dropdown {
    > input.search {
      padding: 0;
    }
  }
  .ui.search {
    font-size: $mobile-small-fontsize;
  }

  /*input text*/
  .ui.form {
    .field > label {
      font-size: $mobile-small-fontsize;
      font-weight: 100;
      line-height: 1.55;
    }
    input[type="text"],
    input[type="tel"],
    input[type="number"] {
      padding: 0.7vw;
      font-size: $mobile-small-fontsize !important;
      min-height: 3.8 * 1.3vw;
      height: 3.8 * 1.3vw;
      border-radius: 0.3vw;
    }
  }

  /* tabs */
  .ui.menu {
    font-size: $mobile-small-fontsize;
  }

  /* buttons */
  .ui.button {
    font-size: $mobile-small-fontsize;
  }
}
