$panels-text: #dce0e0;
$hover-text: #319570;
$top-panel-text: #5D0000;
$left-panel-background:#e2e2e2;
$left-panel-background-hovered: #171d1f;
$form-background: #e1d1cb;

$min-width: 40em;
$max-width: 63.9375em;

$button-positive:#8E6150;

/* usual screen */
$usual-fontsize: 3.6vh;
$small-fontsize: $usual-fontsize / 2;
$large-fontsize: $usual-fontsize * 2;
$superlarge-fontsize: $large-fontsize * 2;


/* mobile */
$mobile-usual-fontsize: 3.6*1.3vw;
$mobile-small-fontsize: $mobile-usual-fontsize / 2;
$mobile-large-fontsize: $mobile-usual-fontsize * 2;
$mobile-superlarge-fontsize: $mobile-large-fontsize * 2;


$padding-standart: 1rem;
$dropdown-max-height: 16.02rem;
$vertical-menu-height: 24.02rem;
